body {
  background-color: var(--main-bg-color);

  font-family: "Poppins", "sans-serif";
  font-size: 16px;
}

#root {
  margin: 0 auto;
  max-width: 528px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

a:hover,
a:focus {
  text-decoration: underline;
}

button {
  background-color: var(--button-bg-color);
  color: var(--button-color);
  font-weight: bold;

  border-radius: 6px;

  padding: 10px 10px;

  cursor: pointer;
}
