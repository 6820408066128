.FancyRadio-container {
  display: flex;
}

.FancyRadio-item {
  width: 100%;
}

.FancyRadio-item--selected {
  background-color: var(--option-selected-bg-color);
  color: var(--option-selected-color);
  border: 1px solid var(--option-selected-color);
}

.FancyRadio-item--not-selected {
  background-color: var(--option-not-selected-bg-color);
  color: var(--option-not-selected-color);
  border: 1px solid var(--option-not-selected-color);
}

.FancyRadio-item:first-child {
  border-radius: 6px 0 0 6px;
}

.FancyRadio-item:last-child {
  border-radius: 0 6px 6px 0;
}

.FancyRadio-input {
  display: none;
}

.FancyRadio-label {
  display: block;
  padding: 15px 24px;
}

.FancyRadio-item--not-selected .FancyRadio-label {
  cursor: pointer;
}
